<template>
  <c-box mt="50px">
    <c-flex :align-items="['left', 'center']" :flexDir="['column', 'row']">
      <c-text flex="1" font-size="xl" font-weight="700">
        Indikator Pertanyaan Terpilih
      </c-text>
      <c-link
        color="brand.900"
        as="router-link"
        :to="{ name: 'nutri.progress-tracker.edit' }"
      >
        Edit Indikator
      </c-link>
    </c-flex>
    <c-box
      mt="30px"
      border-width="1px"
      border-color="brand.900"
      rounded="lg"
      px="45px"
      py="50px"
    >
      <template v-for="(step, index) in steps">
        <CardStep
          v-if="stepIndex === index"
          :key="index"
          :current="index + 1"
          :total="steps.length"
          :is-prev="index !== 0"
          :is-next="index < steps.length - 1"
          @prev="stepIndex--"
          @next="stepIndex++"
        >
          <c-text font-weight="700">
            Bagian {{step.title}}
          </c-text>
          <ul v-chakra mt="20px" pl="20px">
            <li v-for="(question, index) in step.questions" :key="index">
              {{question.question}}
            </li>
          </ul>

          <template v-slot:next>
            <c-text v-if="index === steps.length - 1">Submit</c-text>
            <c-text v-else>Selanjutnya</c-text>
          </template>
        </CardStep>
      </template>
    </c-box>
  </c-box>
</template>

<script>
import CardStep from "@/views/nutritionists/progress-tracker/master/card-step";
import _ from "lodash";

export default {
  name: "NutriProgressMaster",
  components: { CardStep },
  data() {
    return {
      stepIndex: 0,
    };
  },
  computed: {
    questions() {
      return this.$store.getters["nutriProgressTracker/getQuestionsFor"]("identitas");
    },
    steps() {
      let categories = this.$store.state.nutriProgressTracker.categories;
      let steps = categories.map((category) => {
        let questions = this.$store.getters["nutriProgressTracker/getQuestionsFor"](category);
        questions = questions.filter(it => it.checked)
        questions = _.orderBy(questions, "order")

        return {
          title: _.startCase(_.camelCase(category)),
          questions,
        }
      })

      return steps;
    },
  },
  mounted() {
    // this.$store.dispatch("nutriProgressTracker/getQuestions");
    this.$store.dispatch("nutriProgressTracker/getQuestionsForClientId", this.clientId)
        .catch((e) => {
          this.$errorToast({ message: e.message })
        });
  },
};
</script>

<style scoped></style>
